// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "D_f2";
export var eMarketplaceDevBusinessSection = "D_fX";
export var eMarketplaceDevCardBrdrIconSection = "D_fV";
export var eMarketplaceDevCaseSection = "D_f4";
export var eMarketplaceDevClientQuotesSection = "D_f6";
export var eMarketplaceDevExpertiseSection = "D_f0";
export var eMarketplaceDevFeaturesSection = "D_fW";
export var eMarketplaceDevIndustriesSection = "D_fY";
export var eMarketplaceDevOurAchievementsSection = "D_f5";
export var eMarketplaceDevProcessSection = "D_fZ";
export var eMarketplaceDevProjLogoSection = "D_f3";
export var eMarketplaceDevServicesIconSection = "D_fT";
export var eMarketplaceDevTechStackSection = "D_f1";